import React, { useState, useEffect } from 'react'
import Layouts from './layouts/layout'
import './css/style.css';
import { getLeaderboard } from '../services/home';
import { useTranslation } from 'react-i18next';

const LeaderBoard = () => {
    const [leaderBoardList, setLeaderBoardList] = useState([]);
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [searchLead, setSearchLead] = useState('');
    const { t } = useTranslation();
    const handleSearchClick = () => {
        // Toggle the state to expand/collapse the search input box
        setIsSearchExpanded((prevIsSearchExpanded) => !prevIsSearchExpanded);
    };
    const handleSearchChange = (event) => {
        setSearchLead(event.target.value);
    };
    const filteredLeads = leaderBoardList.filter((user) =>
        user.name && user.name.toLowerCase().includes(searchLead.toLowerCase())
    );
    useEffect(() => {
        getLeaderboard().then((res) => {
            console.log(res)
            setLeaderBoardList(res)
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    const dummyImage = '/image/dummy.png';
    return (
        <Layouts>
            <div className='home'>
                <div className="main-div">
                    <div className="container box-wrapper">
                        <div className="header">
                            <div className="row align-items-center">
                                <div className="col-4 text-start">
                                    <div className="logo d-flex">
                                        <a href="/">
                                            <img src="/image/Logo_new.png" alt="Logo" />
                                        </a>
                                        {/* <span className='logo-name'>CaliQuiz</span> */}
                                    </div>
                                </div>
                                <div className="col-8 text-end btnSearchBox">
                                    <div className="search" id="btnSearch">
                                        <img src="/image/search.png" alt="Logo" onClick={handleSearchClick} />
                                    </div>
                                    {isSearchExpanded && (
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="search-input"
                                            onChange={handleSearchChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="page-title">
                            <div className="page-title-circle">
                                {/* <span className="qf-icon-list"></span> */}
                                <img src="/image/more.png" alt="Logo" />
                            </div>
                            <span>{t('5')}</span>
                        </div>
                        <div className="card-simple-outer">
                            <ul className="leaderboard-list-wrapper">
                                {
                                    filteredLeads.map((user, index) => {
                                        return <li className="leaderboard-list">
                                            <div className="d-flex w-100">
                                                <div className="leaderboard-list-pict">
                                                    <img src={user?.image || dummyImage} alt="User" />
                                                    <span>{index + 1}</span>
                                                </div>
                                                <div className="leaderboard-list-info">
                                                    <h4 className="leaderboard-list-info-title">{user?.name || 'Guest'}</h4>
                                                    <p><b>{t('6')}:</b> {user.totalPoints}  </p>
                                                </div>
                                                <div className="leaderboard-score">
                                                    <span>
                                                        <img src="/image/tag.png" alt="Tag" />
                                                        {user.totalPoints}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                            {/* <!-- <div className="p-3">
            <a href="/" className="btn btn-lg button-green w-100">More</a>
        </div> --> */}
                        </div>
                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default LeaderBoard