import React from 'react';
import './css/screen3.css';
import { useTranslation } from 'react-i18next';

import policy from "utils/policy.json";
import { useNavigate } from "react-router-dom";

const Policy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='home'>
      <div className="main">
        <div className="container-fluid box-wrapper difficult">
          <div className="page-header pb-5">
            <div className="box-wrapper mx-auto text-center position-relative header-container">
              <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                <img src="/image/back.png" className='arrow' alt="back" />
              </button>
              <span className="levels-title">{t('12')}</span>
            </div>
          </div>
          <div className="home-profile">
            <h4 className="text-center">{policy.heading}</h4>
            <div className="my-4">
              {policy?.subheadings?.map(section => (
                <div key={section.id} className="mb-3">
                  <h6>
                    {section.id}. {section.title}
                  </h6>
                  {section.subheadings.map(subsection => (
                    <p key={subsection.id} className="policy-description mb-0">
                      <span>{subsection.id ? `${subsection.id}. ` : ""}</span><span>{subsection.content}</span>
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Policy;
