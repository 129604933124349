import api from "./interceptor";

export const loginApi = async (userData) => {
    try {
        const response = await api.post(`auth/adminLogin`, userData);
        return response.data.data.token;
    } catch (e) {
        throw e.response.data;
    }
};

export const sendOtp = async (data) => {
    try {
        const response = await api.post(`auth/sendOtp`, data);
        return response.data.message;
    } catch (e) {
        throw e.response.data;
    }
};

export const login = async (data) => {
    try {
        const response = await api.post(`auth/verifyOtp`, data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getPacks = async (data) => {
    try {
        const response = await api.get(`auth/vasGetPacks`, data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const sendVasOtp = async (data) => {
    try {
        const response = await api.post(`auth/notVasNetworkVasSendOtp`, data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const challangeVasOtp = async (data) => {
    try {
        const response = await api.post(`auth/notVasNetworkVasValidateOtp`, data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const registerVasUserWithISE2 = async (data) => {
    try {
        const response = await api.post(`he`, data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const authenticateWithISE2 = async (ise2Code) => {
    try {
        const response = await api.post(`he/ise2-login`, { ise2Code });
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const subscribeUserVas = async (data) => {
    try {
        const response = await api.post(`auth/vasNetworkSubscribe`, data,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const unSubscribeUser = async (data) => {
    try {
        const response = await api.post(`auth/vasNetworkUnSubscribe`, {},
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};