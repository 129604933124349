// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en.json';
import translationFR from '../locales/fr.json';
import translationAR from '../locales/ar.json';

const resources = {
  0: {
    translation: translationEN,
  },
  1: {
    translation: translationFR,
  },
  2: {
    translation: translationAR,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: '0', // default language
    interpolation: {
      escapeValue: false, // react already escapes values
    },
  });

export default i18n;
