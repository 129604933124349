const Error = () => {

    return (
        <div className="container box-wrapper vh-100 text-center">
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <p>
                    Something went wrong!
                </p>
                <button type="button" className="btn btn-primary" onClick={() => { window.location.href = "/" }}>
                    Go to Home
                </button>
            </div>
        </div>
    );
}

export default Error;
