import React, { useState, useEffect } from "react";
import "../css/login.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import OtpInput from "react-otp-input";
import { getPacks, sendVasOtp, challangeVasOtp, registerVasUserWithISE2, subscribeUserVas, authenticateWithISE2 } from "../../services/auth-api";
import { useTranslation } from "react-i18next";
import { useSession } from "providers/SessionProvider";

const Register = () => {
  const { t } = useTranslation();
  const { getUser, updateToken } = useSession();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [ButtonDisabled, setButtonDisabled] = useState(false);
  const [phone, setPhone] = useState({});
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [counter, setCounter] = useState(0);
  const [packs, setPacks] = useState([]); // State to store packs data
  const [checkingForISE2, setCheckingForISE2] = useState(true);
  const [selectedPack, setSelectedPack] = useState(null);
  const [withoutTokenPackSelected, setWithoutTokenPackSelected] = useState(false); // State to track if data is loaded
  const [challangeUrl, setChallangeUrl] = useState("");
  const [ISE2, setISE2] = useState()

  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");

  const handlePackSelection = (pack) => {
    setSelectedPack(pack);
  };

  const loginAfterISE2 = async (tok, selectpack) => {
    await registerVasUserWithISE2({ ISE2: tok, packId: selectpack, mobileNumber: phone })
      .then(async (res) => {
        console.log(res);
        updateToken(res.data.token)
        //   let packObj = {
        //     "id": "1",
        //     "pack_name": "Pack 1",
        //     "pack_frequency": "Daily",
        //     "pack_price": "2",
        //     "pack_currency": "SSL",
        //     "createdAt": "2024-03-29T10:30:39.000Z",
        //     "updatedAt": "2024-03-29T10:30:39.000Z"
        // }
        //   await subscribeUser(tok, packObj)
        await subscribeUser(tok, packs.find((data) => data.id === selectpack))

        const path = localStorage.getItem("menu") ? "/" + localStorage.getItem("menu") : "/";
        navigate(path);

      })
      .catch((err) => {
        console.log(err);
        setMessage({
          message: err.error,
          error: true,
        });
      });
  }

  const handleConfirmSubscription = async () => {
    if (selectedPack) {
      console.log(`Confirmed subscription to Pack ${selectedPack}`);
      setWithoutTokenPackSelected(true);
      //navigate('/home')
      if (token || ISE2) {
        loginAfterISE2(token ? token : ISE2, selectedPack)
      }

      // You can implement further logic here, such as sending a request to the server to confirm the subscription
    } else {
      console.log("Please select a pack before confirming");
    }
  };

  useEffect(() => {
    if (withoutTokenPackSelected) {
      // Check if token exists and data is not loaded
      getPacks()
        .then((res) => {
          console.log("All packs ", res);
          setPacks(res?.data); // Set packs data
          // setIsLoaded(true); // Mark data as loaded
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token, withoutTokenPackSelected]);

  const [message, setMessage] = useState({
    message: "",
    error: false,
  });

  const validatePhoneNumber = (phoneNumber) => {
    // Define a regular expression for a typical 10-digit US phone number
    const phoneRegex = /^\d{8}$/;

    // Test the provided phone number against the regular expression
    return phoneRegex.test(phoneNumber);
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const sendOtpUser = async () => {
    if (validatePhoneNumber(phone)) {
      if (counter <= 0) {
        setCounter(30);
        setMessage({
          message: "",
          error: false,
        });

        await sendVasOtp({ phone: parseInt(phone) })
          .then((res) => {
            console.log(res);
            setChallangeUrl(res?.location?.location);
            setIsOTPSent(true);

            if (res?.isExistingUser) {
              navigate("/login");
            }
          })
          .catch((err) => {
            console.log(err);
            setMessage({
              message: err.error,
              error: true,
            });
          });
      } else {
        alert("Please Wait");
      }
    } else {
      setMessage({
        message: "Phone Number is not valid",
        error: true,
      });
    }
  };

  const handleNumInputsChange = (numInputs) => {
    setOtp(numInputs);
    setButtonDisabled(numInputs.length !== 4 || isNaN(numInputs));
  };

  const handleButtonClick = async () => {
    await challangeVasOtp({ challangeurl: challangeUrl, phone: parseInt(phone), otp: otp })
      .then((res) => {
        setISE2(res.data.challenge.result[0].value)

        if (res.isExistingUser) {
          updateToken(res.token);
          const path = localStorage.getItem("menu") ? "/" + localStorage.getItem("menu") : "/"
          navigate(path);
          return;
        }

        // loginAfterISE2(res.data.challenge.result[0].value, selectedPack)
        setWithoutTokenPackSelected(true)
      })
      .catch((err) => {
        console.log(err);
        setMessage({
          message: err.error,
          error: true,
        });
      });
  };

  const subscribeUser = async (ise2, selectPack) => {
    await subscribeUserVas({ ise2, selectPack })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err);
        setMessage({
          message: err.error,
          error: true,
        });
      });
  }

  useEffect(() => {
    if (searchParams.get("token")) {
      setToken(searchParams.get("token"));
    }
  }, [searchParams])

  /**
  * Network login
  */
  useEffect(() => {
    if (token) {
      authenticateWithISE2(encodeURIComponent(token)).then(({ data }) => {
        if (data?.token) {
          localStorage.setItem("token", data.token);
          getUser();
          navigate("/home");
        } else {
          navigate("/register");
        }
      }).catch(err => {
        setMessage({
          message: err.error,
          error: true,
        });
      }).finally(() => {
        setCheckingForISE2(false);
      });
    }
  }, [token, navigate, getUser]);

  if (token && checkingForISE2) {
    return (
      <div className="container-fluid">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="home">
      <div className="main-div">
        <div className="container-fluid box-wrapper login">
          <div className="pt-5">
            <div className="login-thumbnail">
              <img src="/image/login.png" alt="Quiz" />
            </div>
          </div>
          <div className="login-header pb-4">
            <div className="box-wrapper mx-auto text-center position-relative">
              <span className="modal-level-title game-title">{t("50")}</span>
            </div>
          </div>
          <div className="login-info">
            <div className="login-thumbnail2">
              <img src="/image/login_img.png" alt="Quiz" />
            </div>
            {withoutTokenPackSelected ? (
              <div className="pack-main-container">
                <div className="pack-title">{t("68")}</div>
                <div className="pack-container">
                  {packs.map((pack) => (
                    <div key={pack.id} className="pack">
                      <div className="pack_row">
                        <div className="pack-header">{pack.pack_name}</div>
                        <input
                          type="radio"
                          name="pack"
                          checked={selectedPack === pack.id}
                          onChange={() => handlePackSelection(pack.id)}
                        />
                      </div>
                      <div className="pack_row">
                        <div className="pack-details">
                          {pack.pack_price + " " + pack.pack_currency}/
                          {pack.pack_frequency}
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* Add two more packs similarly if needed */}
                </div>
                <button
                  className="pack-btn btn btn-primary btn-pm-custom"
                  onClick={handleConfirmSubscription}
                >
                  {t("69")}
                </button>
              </div>
            ) : null}
            {/* start */}
            {!token && !withoutTokenPackSelected ? (
              <>
                <h3 className="login-title mb-3 mt-3 text-center">{t("65")}</h3>
                {message.message && (
                  <div className="mb-3">
                    <p
                      className={`error alert-${message.error ? "danger" : "success"
                        }`}
                    >
                      {message.message}
                    </p>
                  </div>
                )}

                <div className="phone-info">
                  {!isOTPSent && (
                    <>
                      <div className="phone-number-info">
                        <input
                          className="form-control form-mobile-no register-mobile-no"
                          placeholder={t("66")}
                          type="tel"
                          maxLength={8}
                          inputMode="numeric"
                          onChange={(event) => {
                            setPhone(event.target.value);
                          }}
                        />
                      </div>
                      <button
                        className="pack-btn btn btn-primary btn-pm-custom-send-otp"
                        onClick={() => sendOtpUser()}
                      >
                        {t("70")}
                      </button>
                    </>
                  )}

                  {isOTPSent && (
                    <>
                      <p className="Verify_otp">{t("53")}</p>

                      <div className="otp_section">
                        <OtpInput
                          value={otp}
                          numInputs={4}
                          renderInput={(props, index) => (
                            <input
                              {...props}
                              type="tel" // Use type="tel" to display the numeric keypad on mobile devices
                              inputMode="numeric" // Specify the numeric input mode
                            />
                          )}
                          inputStyle="form-control form-otp"
                          onChange={handleNumInputsChange}
                        />
                      </div>
                      <button
                        className="btn btn-primary btn-pm-custom"
                        type="button"
                        onClick={handleButtonClick}
                        disabled={ButtonDisabled}
                      >
                        {t("64")}
                      </button>

                      {/* <div className="resend_otp">
                                    {t('55')} <NavLink to='/#' className="send_otp_link"> {t('56')}</NavLink>
                                </div> */}
                    </>
                  )}
                </div>
              </>
            ) : null}
            {/* <button className="btn btn-primary btn-pm-custom" type="button" onClick={() => loginAfterISE2('PDKSUB-200-8Vzf4GgLJt02DbgGHS/bhmVbhw3IsEDKWbYj0AHhJNA=', 1)} value="test"/> */}
            {/* end  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
