import api from "./interceptor";

export const getAllQuiz = async (currentLanguage) => {
    try {
        const response = await api.get(`quiz?lang_id=${currentLanguage}`)
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}



export const getAllQuestion = async (quizId) => {
    try {
        const response = await api.get(`quiz/question/all/${quizId}`)
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}



export const getAllLevel = async () => {
    try {
        const response = await api.get(`level`)
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}
export const getAllOpponent = async () => {
    try {
        const response = await api.get("/quiz/findOpponent");
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}

export const getLeaderboard = async () => {
    try {
        const response = await api.get(`quiz/leaderboard`)
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}
export const getQuiz = async (quizId, levelId, currentLanguage) => {
    try {
        const response = await api.get(`quiz/${quizId}/${levelId}?lang_id=${currentLanguage}`)
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}

export const getHistory = async (currentLanguage) => {
    try {
        const response = await api.get(`quiz/history?lang_id=${currentLanguage}`);
        return response.data.data;
    } catch (e) {
        throw e.response.data;
    }
};

// export const getProfile = async () => {
//     try {
//         const response = await api.get(`auth/profile`,
//             {
//                 headers: {
//                     'Authorization': `Bearer ${token()}`
//                 }
//             })
//         return response.data.data
//     } catch (e) {
//         throw e.response.data
//     }
// }
export const updateProfile = async (formData) => {
    try {
        const response = await api.patch(`auth/profile`, formData)
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}

export const updateScore = async (quizId, levelId, totalPoints) => {
    try {
        const response = await api.post(`quiz/points/${quizId}/${levelId}`, totalPoints)
        return response.data.data
    } catch (e) {
        throw e.response.data
    }
}