import React, { useState } from 'react'
import './css/loss.css';
import BottomNav from './layouts/bottomNav';
import { useTranslation } from 'react-i18next';
const WinGames = () => {
    const [statsVisible, setStatsVisible] = useState(false);
    const { t } = useTranslation();
    const toggleStatsVisibility = () => {
        setStatsVisible(!statsVisible);
    };
    return (
        <>
            <div className='home'>
                <div className="main-div">
                    <div className="page-header">
                        <div className="box-wrapper mx-auto text-center position-relative">
                            {t('31')}
                        </div>
                    </div>
                    <div className="container box-wrapper">
                        <div className="text-center pb-5">
                            <div className="quiz-result-pict">
                                <div className="quiz-result-pict-ava ">
                                    <img src="/image/won.png" alt="win" />
                                </div>
                            </div>
                            <h4 className="quiz-result-status pt-5 pb-5">{t('57')}!</h4>

                            <div className="quiz-result-points">
                                <div className='d-flex justify-content-between'>
                                    <span className="quiz-result-points-badge">
                                        <img src="/image/Group 3.png" alt="badge" />
                                    </span>
                                    <spna className="pe-1">21020 {t('17')}</spna>
                                </div>
                            </div>
                        </div>

                        <div className="final-score" >
                            <div className="text-center final-card">
                                <div className="final-score-title">
                                    <p className="m-0">{t('33')}</p>
                                </div>
                            </div>
                            <div className="final-score-top">
                                <div className="row">
                                    <div className="col-6 text-center">
                                        <div className="final-score-top-box">10</div>
                                        <span className="mt-2 d-block final_name">Canon</span>
                                    </div>
                                    <div className="col-6 text-center">
                                        <div className="final-score-top-box2">0</div>
                                        <span className="mt-2 d-block final_name">{t('26')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="final-score-detail" id="btnStats">
                                <h4 className="final-score-detail-title" onClick={toggleStatsVisibility}>{t('60')} <span> <img src="/image/arrow_drop_down.png" alt="drop down" /></span></h4>
                                <ul className="final-score-more" style={{ display: statsVisible ? "block" : "none" }}>
                                    <li>
                                        <div className="row gx-0">
                                            <div className="col-8 text-start">
                                                <p className="mb-1 fw-bold">Canon</p>
                                                <p className="mb-0">
                                                    <span className='right-text'>{t('34')}:</span> <span className="score-stats-badge">1</span>
                                                    <span className='wrong-text'>{t('35')}:</span> <span className="score-stats-badge2">0</span>
                                                </p>
                                            </div>
                                            <div className="col-4 text-end">
                                                <p className="mb-1 p_color">1.14</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row gx-0">
                                            <div className="col-8 text-start">
                                                <p className="mb-1 fw-bold">1234567890</p>
                                                <p className="mb-0">
                                                    <span className='right-text'>{t('34')}:</span> <span className="score-stats-badge">0</span>
                                                    <span className='wrong-text'>{t('35')}:</span> <span className="score-stats-badge2">68</span>
                                                </p>
                                            </div>
                                            <div className="col-4 text-end">
                                                <p className="mb-1 p_color">0</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BottomNav />
        </>
    )
}

export default WinGames