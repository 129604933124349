import React, { useState, useEffect } from 'react'
import './css/screen3.css';
import { updateProfile } from '../services/home';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { DEFAULT_AVATAR_SRC } from '../utils/constants';
import { useSession } from 'providers/SessionProvider';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const EditProfile = () => {
    const { user: profile, getUser } = useSession();
    const navigate = useNavigate();
    const [state, setState] = useState({
        name: "",
        image: "",
    });
    const [profileImage, setProfileImage] = useState(null);
    const [isFormError, setIsFormError] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setProfileImage(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };
    const handleFileRemove = () => {
        setProfileImage(null);
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        await updateProfile({
            ...state,
            image: profileImage?.slice(0, 4) !== "http" ? profileImage : undefined
        }).then(res => {
            toast("Profile update has been completed successfully.")
            getUser();
            navigate("/")
        }).catch(err => {
            toast("An error occurred; please try again later.")
            console.log(err)
        })

    }

    const { t } = useTranslation();

    const validateForm = () => {
        if ((profileImage === profile.image) && !state?.name) {
            setIsFormError(true);

            return false;
        } else {
            setIsFormError(false);

            return true;
        }
    }

    useEffect(() => {
        if (profile) {
            setState(prevState => ({
                ...prevState,
                name: profile.name || ""
            }));
            setProfileImage(profile.image);
        }
    }, [profile]);

    return (
        <><ToastContainer /><div className='home'>
            <div className="main-div">
                <div className="container-fluid box-wrapper difficult">
                    <div className="page-header pb-5">
                        <div className="box-wrapper mx-auto text-center position-relative header-container">
                            <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                                <img src="/image/back.png" className='arrow' alt="back" />
                            </button>
                            <span className="levels-title">{t('10')}</span>
                        </div>
                    </div>
                    <div className="edit-profile">
                        <Form className="pb-2" onSubmit={onSubmit} noValidate>
                            <div className="row gx-0  d-flex">
                                <div className="col-3 text-start">
                                    <div className="d-flex align-items-baseline">
                                        <div className="user-avatar">
                                            <img src={profileImage || DEFAULT_AVATAR_SRC} alt="Avatar" className="profile" />
                                            <span className="delete-avatar"><img src="/image/delete.png" alt="delete" className="delete" onClick={handleFileRemove} /></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-9 text-start avatar-title">
                                    <div className="flex-grow-1 ms-2">
                                        <div className="edit-profile-name">Avatar</div>
                                        <div className="choose-file-wrapper">
                                            <button className="choose-file"><img src="/image/cloud_upload.png" alt="upload" className="cloud_upload" /> {t('40')}</button>
                                            <input type="file" className="myChoosefile" name="file" onChange={handleFileChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-start profile-setting">
                                <hr />
                                <Form.Group>
                                    <Form.Label htmlFor="exampleInputEmail1">{t('41')}</Form.Label>
                                    <Form.Control type="text" className="form-control" placeholder="Enter Name" value={state.name} onChange={(event) => setState({ ...state, name: event.target.value })} />
                                    {isFormError && (<small className="danger fw-semibold">Please enter name or update profile picture</small>)}
                                </Form.Group>

                                <button type="submit" className="btn btn-primary">{t('42')}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div ></>
    )
}

export default EditProfile