import React from 'react' 
import Footer from './footer'
const Layouts = (props) => { 
  return (
    <> 
    {props.children}
    <Footer/> 
    </>
  )
}

export default Layouts