import React, { useEffect, useState } from 'react'
import './css/loss.css';
import BottomNav from './layouts/bottomNav';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import { useSession } from 'providers/SessionProvider';

const LossGames = (props) => {
    const { user: profile, getUser } = useSession();

    useEffect(() => {

        if (props.isScoreCalculated) {
            getUser();
            playSound(props.userScore > props.opponentScore)
        }
    }, [props.isScoreCalculated]);

    const playSound = (answer) => {
        const audio = new Audio(`/sounds/${answer ? "winner" : "looser"}.mp3`); // Use the absolute path to the sound file
        audio.play();
    };

    const { t } = useTranslation();

    return (
        <>
            {props.userScore > props.opponentScore && <Confetti
                width={window.width}
                height={window.height}
            />}
            <div className='home'>
                <div className="main-div">
                    <div className="page-header">
                        <div className="box-wrapper mx-auto text-center position-relative">
                            {t('31')}
                        </div>
                    </div>
                    <div className="container box-wrapper loss-wrap">
                        <div className="text-center pb-4">
                            <div className="quiz-result-pict">
                                <div className="quiz-result-pict-ava ">
                                    {props.userScore > props.opponentScore ? (
                                        <img src="/image/won.png" alt="win" />
                                    ) : (
                                        <img src="/image/loss.png" alt="lose" />
                                    )}
                                </div>
                            </div>
                            <h4 className="quiz-result-status pt-5">{t('26')} {props.userScore > props.opponentScore ? `${t("61")}` : `${t("62")}`}!</h4>
                            {props.userScore > props.opponentScore ? '' : <p className="quiz-result-ann pb-3">{t('32')}!</p>}

                            <div className="quiz-result-points">
                                <div className='d-flex justify-content-between'>
                                    <span className="quiz-result-points-badge">
                                        <img src="/image/Group 3.png" alt="badge" />
                                    </span>
                                    <span className="pe-3">{profile?.totalPoints || 0} {t('17')}</span>
                                </div>
                            </div>
                        </div>

                        <div className="final-score" >
                            <div className="text-center final-card">
                                <div className="final-score-title">
                                    <p className="m-0">{t('33')}</p>
                                </div>
                            </div>
                            <div className="final-score-top">
                                <div className="row">
                                    <div className="col-6 text-center">
                                        <div className="final-score-top-box">{props.userScore * props.state.level.perQuestion}</div>
                                        <span className="mt-2 d-block final_name">{t('26')}</span>
                                        <div className="row gx-0 final-score-more">
                                            <div className="col-12 text-center">
                                                <p className="mb-0">
                                                    <span className='right-text'>{t('34')}:</span> <span className="score-stats-badge">{props.userScore}</span>
                                                    <span className='wrong-text'>{t('35')}:</span> <span className="score-stats-badge2">{props.totalQuestions - props.userScore}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 text-center">
                                        <div className="final-score-top-box2">{props.opponentScore * props.state.level.perQuestion}</div>
                                        <span className="mt-2 d-block final_name">{props.state.players?.opponent.name}</span>
                                        <div className="row gx-0 final-score-more">
                                            <div className="col-12 text-center">
                                                <p className="mb-0">
                                                    <span className='right-text'>{t('34')}:</span> <span className="score-stats-badge">{props.opponentScore}</span>
                                                    <span className='wrong-text'>{t('35')}:</span> <span className="score-stats-badge2">{props.totalQuestions - props.opponentScore}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BottomNav state={props.state.qid} />
        </>
    )
}

export default LossGames