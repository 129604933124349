import { Navigate, Route, Routes } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import Profile from '../components/profile'
import PlayGames from '../components/play'
import LossGames from '../components/loss'
import History from '../components/history'
import Edit from '../components/editProfile'
import Policy from '../components/policy'
import ModalPlay from '../components/modelPlay'
import WinGames from '../components/win'
import Language from '../components/language'
import Contactus from '../components/contactUs'
import Screen2 from '../components/screen2'
import Screen3 from '../components/screen3'
import AllQuizes from '../components/allQuizes'
import GameDetails from '../components/gameDetails'
import LeaderBoard from '../components/leaderBoard'
import FindingOpponent from '../components/findingOpponent'
import Register from "components/login/register";

const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<AuthGuard isProtected={false} />}>
                <Route path='/register' element={<Register />} />
            </Route>
            <Route element={<AuthGuard />} isProtected>
                <Route exact path='/' element={<Screen2 />} />
                <Route path='/home' element={<Screen2 />} />
                <Route path='/levels' element={<Screen3 />} />
                <Route path='/moregames' element={<AllQuizes />} />
                <Route path='/gamedetails' element={<GameDetails />} />
                <Route path='/leaderboard' element={<LeaderBoard />} />
                <Route path='/finding' element={<FindingOpponent />} />

                <Route path='/profile' element={<Profile />} />
                <Route path='/play' element={<PlayGames />} />
                <Route path='/loss' element={<LossGames />} />
                <Route path='/history' element={<History />} />
                <Route path='/edit' element={<Edit />} />
                <Route path='/policy' element={<Policy />} />
                <Route path='/modal' element={<ModalPlay />} />
                <Route path='/won' element={<WinGames />} />
                <Route path='/language' element={<Language />} />
                <Route path='/contactus' element={<Contactus />} />
            </Route>
            <Route path='*' element={<Navigate to="/register" />} />
        </Routes>
    )
}

export default AppRoutes;
