import React from 'react'
import './css/screen3.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Language = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='home'>
      <div className="main-div">
        <div className="container-fluid box-wrapper difficult">
          <div className="page-header pb-5">
            <div className="box-wrapper mx-auto text-center position-relative header-container">
              <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                <img src="/image/back.png" className='arrow' alt="back" />
              </button>
              <span className="levels-title">{t('11')}</span>
            </div>
          </div>
          <div className="home-profile">
            <h3 className="choose-title mb-3">{t('43')}:</h3>

            <form className="languageForm">
              <div className="choose-option">
                <label htmlFor="forone">English</label>
                <input id="forone" type="radio" name="r" value="0" checked />
              </div>
              <div className="choose-option">
                <label htmlFor="fortwo">Arabic</label>
                <input id="fortwo" type="radio" name="r" value="2" />
              </div>
              <div className="choose-option">
                <label htmlFor="forthree">French</label>
                <input id="forthree" type="radio" name="r" value="1" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Language