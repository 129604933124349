const { useSession } = require("providers/SessionProvider");
const { Outlet, Navigate } = require("react-router-dom");

const AuthGuard = ({ isProtected = true }) => {
    const { getAccessToken } = useSession();

    return (
        isProtected
            ? Boolean(getAccessToken())
                ? <Outlet /> : <Navigate to="/register" />
            : !Boolean(getAccessToken())
                ? <Outlet /> : <Navigate to="/home" />
    );
}

export default AuthGuard;
