import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n from './utils/i18n';
import { I18nextProvider } from 'react-i18next';
import ErrorBoundary from './utils/ErrorBoundary';
import Error from './utils/Error';
import { SessionProvider } from 'providers/SessionProvider';
import AppRoutes from 'routes/AppRoutes';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary fallback={<Error />}>
        <SessionProvider>
          <Router>
            <AppRoutes />
          </Router>
        </SessionProvider>
      </ErrorBoundary>
    </I18nextProvider>
  );
}

export default App;
