import React, { useState } from 'react'
import '../css/bottom.css'
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const BottomNav = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="bottom-spacer" style={{ height: "65px" }}></div>
            <div className="bottom-nav">
                <div className="bottom-nav-bg row gx-0 box-wrapper mx-auto container-fluid">
                    <div className="col">
                        <div className="nav-box">
                            <NavLink to="/home" className="nav-box-ico">
                                <span className="qf-icon-home pe-3"><img src="/image/home2.png" alt="home" /></span>
                                <span className="text-uppercase" style={{ fontSize: "20px" }}>{t('36')}</span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="col">
                        <div className="nav-box background-primary" style={{ borderRadius: "0 10px 0 0" }}>
                            <NavLink to="/gamedetails" className="nav-box-ico" state={props.state}>
                                <span className="qf-icon-replay pe-3"><img src="/image/refresh.png" alt="refresh" /></span>
                                <span className="text-uppercase" style={{ fontSize: "20px" }}>{t('37')}</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default BottomNav