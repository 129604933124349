import React from 'react'
import './css/screen3.css';
import { NavLink, useLocation } from 'react-router-dom';
import { levels } from '../utils/levels';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Screen3 = () => {
    const location = useLocation();
    const navigate = useNavigate();
    console.log("location---", location);
    console.log("levels--", levels);
    const { t } = useTranslation();

    return (
        <div className='home'>
            <div className="main-div">
                <div className="container-fluid box-wrapper difficult">
                    <div className="page-header pb-5">
                        <div className="box-wrapper mx-auto text-center position-relative header-container">
                            <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                                <img src="/image/back.png" className='arrow' alt="back" />
                            </button>
                            <span className="levels-title">{t('20')}</span>
                        </div>
                    </div>
                    <div className="home-profile">
                        <h3 className="choose-title mb-3">{t('21')}</h3>
                        <div className='pb-2'>
                            <NavLink to="/finding" state={{ qid: location?.state, level: levels[0] }}>
                                <div className="row gx-0 beginner-tab d-flex align-items-baseline">
                                    <div className="col-7 text-start">
                                        <div className="d-flex align-items-baseline">
                                            <div className="avatar2">
                                                <img src="/image/Beginner.png" alt="Avatar" />
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                <div className="home-profile-name">{t('22')}</div>
                                                {/* <div className="home-profile-status">Guest</div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="h-100 d-flex align-items-center justify-content-end">
                                            <div className="home-profile-points">
                                                <span className='points-text'> {levels[0].points} {t('17')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className='pb-2'>
                            <NavLink to="/finding" state={{ qid: location?.state, level: levels[1] }}>
                                <div className="row gx-0 intermediate-tab d-flex align-items-baseline">
                                    <div className="col-7 text-start">
                                        <div className="d-flex align-items-baseline">
                                            <div className="avatar2">
                                                <img src="/image/Intermediate.png" alt="Avatar" />
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                <div className="home-profile-name">{t('23')}</div>
                                                {/* <div className="home-profile-status">Guest</div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="h-100 d-flex align-items-center justify-content-end">
                                            <div className="home-profile-points">
                                                <span className='points-text'> {levels[1].points} {t('17')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink to="/finding" state={{ qid: location?.state, level: levels[2] }}>
                                <div className="row gx-0 advance-tab d-flex align-items-baseline">
                                    <div className="col-7 text-start">
                                        <div className="d-flex align-items-baseline">
                                            <div className="avatar2">
                                                <img src="/image/Advance.png" alt="Avatar" />
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                <div className="home-profile-name">{t('24')}</div>
                                                {/* <div className="home-profile-status">Guest</div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5 text-end">
                                        <div className="h-100 d-flex align-items-center justify-content-end">
                                            <div className="home-profile-points">
                                                <span className='points-text'> {levels[2].points} {t('17')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Screen3